.registerBody {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;

    .registerBox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .ant-col-16 {
            width: 100%;
        }

        .ant-form-item:nth-of-type(4) {
            margin-bottom: 0px;
        }

        .ant-btn {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

header {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        color: rgb(192, 192, 192);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
            line-height: 64px;
            letter-spacing: 3px;
        }
    }
}

.loginContainer {
    width: 100vw;
    height: calc(100vh - 50px - 64px);
    display: block;
}

// 浏览器
@media screen and (min-width: 600px) {
    * {
        font-size: 14px;
    }

    .registerBox {
        width: 20vmax;

        .ant-form-item {
            margin-bottom: 20px;
        }

        a {
            color: #d0d0d0;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    header {
        .title {
            .name {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .registerBox {
        width: 240px;
    }
}

// 手机
@media screen and (max-width: 600px) {
    * {
        font-size: 10px;
    }

    .registerBox {
        width: 240px;

        .ant-form-item {
            margin-bottom: 10px;
        }

        a {
            color: rgb(146, 158, 224);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    header {
        .title {
            .name {
                font-size: 12px;
            }
        }
    }

    .ant-layout-footer {
        padding: 0px 46px !important;
    }
}
