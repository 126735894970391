@import '~antd/dist/antd.css';

@bgcolor: rgba(255, 255, 255, 0.966);
@headerheight: 64px;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    background-color: @bgcolor;
    font-size: 16px;
    font-family: '微软雅黑';
}

a {
    text-decoration: none;
}

#root {
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
}

.ant-layout-header {
    height: @headerheight;
    padding: 0 30px;
    color: rgba(0, 0, 0, 0.85);
    line-height: @headerheight;
    background: #001529;
}

.container {
    height: calc(100vh - 64px - 60px);
    .content {
        background: #fff;
        overflow-y: auto;
        padding: 10px;
        &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 10px;
            height: 100%;
            background: #fff;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: #ededed;
        }

        &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #001a3398;
        }
    }
}
