@headerheight: 64px;

* {
    margin: 0;
    padding: 0;
}

header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        color: rgb(192, 192, 192);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            width: 40px;
            height: 40px;
            margin-right: 20px;
        }
        .name {
            line-height: @headerheight;
        }
    }
}
