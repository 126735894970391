.epsApplyList {
    position: relative;
    a {
        color: rgb(0, 0, 0);
        &:hover {
            color: rgb(29, 96, 221);
        }
    }
    .pagination {
        position: absolute;
        right: 40px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
