@import '@wangeditor/editor/dist/css/style.css';

.editor {
    padding: 20px;
    .ant-form-item:nth-of-type(3) {
        margin-right: 0;
    }
    .ant-input {
        width: 400px;
    }
    .editor-content {
        display: block;

        div:nth-of-type(2) {
            resize: vertical;
        }
    }

    .editor-content-hide {
        display: none;
    }
    .editor-content-view-hide {
        display: none;
    }

    .editor-content-view {
        height: 580px;
        display: block;
        border: 1px solid #c0c0c0;
        padding: 10px;
        li {
            margin-left: 15px;
        }
    }
}
